<template>
  <div>
    <b-overlay
      :show="overlay"
      opacity="0.17"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >
      <b-sidebar
        id="sidebar-backdrop"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        backdrop
        right
        no-header
        shadow
      >
        <NonImportedStandardsView
          :openSidebar="openSidebar"
          :closeSidebar="closeSidebar"
          :showOverlay="showOverlay"
          :hideOverlay="hideOverlay"
          :reloadParent="load"
        />
      </b-sidebar>
      <b-tabs content-class="mt-2" justified>
        <b-tab title="My Standards">
          <div class="card">
            <table role="table" class="table b-table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Industries</th>
                  <!-- <th role="columnheader" scope="col" class="text-center">
                    Version
                  </th> -->
                  <th role="columnheader" scope="col">Regions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="items && items.length > 0">
                  <template v-for="standard in items">
                    <tr
                      :key="standard._id"
                      role="row"
                      class="cursor-pointer"
                      @click="handleMyStandardClick(standard._id)"
                    >
                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                          <p class="mb-0 font-weight-bold">
                            {{ standard.name }}
                          </p>

                          <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          >
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.top.v-primary
                          :title="standard.description"
                        >
                          {{ standard.description }}
                        </p>
                      </td>

                      <td style="max-width: 15vw" role="cell">
                        <div
                          class="d-flex flex-wrap align-items-center justify-content-start w-100"
                        >
                          <template
                            v-if="
                              standard.industries && standard.industries != null&&standard.industries.length
                            "
                          >
                            <template v-for="(stnd, i) in standard.industries">
                              <b-badge
                                variant="primary"
                                :key="i"
                                class="mr-50 my-25"
                                >{{ stnd.name }}</b-badge
                              >
                            </template>
                          </template>
                          <template v-else>
                            <b-badge variant="success">Global</b-badge>
                          </template>
                        </div>
                      </td>
                      <!-- 
                      <td aria-colindex="2" role="cell">
                        <div
                          class="w-100 d-flex align-items-center justify-content-center"
                        >
                          <b-badge variant="primary">{{
                            standard.version
                          }}</b-badge>
                        </div>
                      </td> -->

                      <!-- <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex align-items-center justify-content-start"
                        >
                          <template v-for="(re, i) in standard.region">
                            <b-badge
                              variant="light-primary"
                              :key="i"
                              v-if="i < 2"
                              class="mr-50"
                              >{{ re }}</b-badge
                            >
                          </template>
                          <span v-if="standard.region.length > 2" :key="i"
                            >{{ standard.region.length - 2 }} more..</span
                          >
                        </div>
                      </td> -->
                      <td style="max-width: 15vw" role="cell">
                        <div
                          class="d-flex flex-wrap align-items-center justify-content-start w-100"
                        >
                          <template
                            v-if="
                              standard.countries &&
                              standard.countries != null &&
                              standard.countries.length > 0
                            "
                          >
                            <template v-for="(stnd, i) in standard.countries">
                              <b-badge
                                variant="primary"
                                :key="i"
                                class="mr-50 my-25"
                                >{{ stnd.name }}</b-badge
                              >
                            </template>
                          </template>
                          <template v-else>
                            <b-badge variant="success">Global</b-badge>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <empty-table-section title="Standard Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no Standards at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
            <div class="card-body py-0 pt-1">
              <div
                class="d-flex align-items-center justify-content-center w-100"
              >
                <b-pagination
                  v-model="importedStandardsFilters.page"
                  :per-page="importedStandardsPagination.perPage"
                  :total-rows="
                    importedStandardsPagination.perPage *
                    importedStandardsPagination.lastPage
                  "
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Standard Library">
          <div class="card">
            <div class="card-header">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="nonImportedFilters.search"
                  placeholder="Search Standards..."
                ></b-form-input>
              </b-input-group>
            </div>
            <table role="table" class="table b-table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Industry</th>
                  <!-- <th role="columnheader" scope="col" class="text-center">
                    Version
                  </th> -->
                  <th role="columnheader" scope="col" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template
                  v-if="nonImportedStandards && nonImportedStandards.length > 0"
                >
                  <template v-for="standard in nonImportedStandards">
                    <tr :key="standard._id" role="row" class="cursor-pointer">
                        <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                          <p class="mb-0 font-weight-bold">
                            {{ standard.name }}
                          </p>

                          <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          >
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.top.v-primary
                          :title="standard.description"
                        >
                          {{ standard.description }}
                        </p>
                      </td>

                      <td style="max-width: 15vw" role="cell">
                        <div
                          class="d-flex flex-wrap align-items-center justify-content-start w-100"
                        >
                          <template
                            v-if="
                              standard.industries && standard.industries != null
                            "
                          >
                            <template v-for="(stnd, i) in standard.industries">
                              <b-badge
                                variant="primary"
                                :key="i"
                                class="mr-50 my-25"
                                >{{ stnd.name }}</b-badge
                              >
                            </template>
                          </template>
                          <template v-else>
                            <span></span>
                          </template>
                        </div>
                      </td>

                      <!-- <td aria-colindex="2" role="cell">
                        <div
                          class="w-100 d-flex align-items-center justify-content-center"
                        >
                          <b-badge variant="light-primary">{{
                            standard.version
                          }}</b-badge>
                        </div>
                      </td> -->

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <b-button
                            @click="handleImportStandardClick(standard._id)"
                            size="sm"
                            variant="success"
                            ><feather-icon
                              icon="DownloadIcon"
                              class="mr-50"
                            />Import</b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <empty-table-section title="Standards Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no Standards at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>

            <div class="card-body py-0 pt-1">
              <div
                class="d-flex align-items-center justify-content-center w-100"
              >
                <b-pagination
                  v-model="nonImportedFilters.page"
                  :per-page="nonImportedPagination.perPage"
                  :total-rows="
                    nonImportedPagination.perPage *
                    nonImportedPagination.lastPage
                  "
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BButton,
  VBToggle,
  BSidebar,
  BOverlay,
  BTabs,
  BTab,
  VBTooltip,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import StandardMixins from "../../mixins/StandardMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import NonImportedStandardsView from "./components/NonImportedStandardsView.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  name: "Standards",
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [StandardMixins, ResponseMixins, UtilsMixins],
  components: {
    BCard,
    BCardBody,
    BTable,
    BButton,
    BSidebar,
    NonImportedStandardsView,
    BOverlay,
    BTabs,
    BTab,
    VBTooltip,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BBadge,
    BPagination,
    EmptyTableSection,
  },
  directives: { "b-toggle": VBToggle },
  data() {
    return {
      fields: [
        { key: "name", label: "Name", tdClass: "font-weight-bold" },
        { key: "description", label: "Description", tdClass: "font-italic" },
        { key: "industry", label: "Industry", tdClass: "font-weight-bold" },
        { key: "version", label: "Version", tdClass: "font-weight-bold" },
        { key: "region", label: "Region", tdClass: "font-weight-bold" },
      ],
      items: [],
      nonImportedStandards: [],
      nonImportedFilters: {
        search: null,
        page: 1,
      },
      nonImportedPagination: {
        lastPage: 1,
        perPage: 10,
        total: 1,
      },
      importedStandardsPagination: {
        lastPage: 1,
        perPage: 10,
        total: 1,
      },
      importedStandardsFilters: {
        page: 1,
      },
      overlay: false,
    };
  },
  watch: {
    nonImportedFilters: {
      handler: function (newFilters) {
        this.debounceFunction(
          this.getAllNonImportedStandardsAndSetData(newFilters)
        );
      },
      deep: true,
    },

    importedStandardsFilters: {
      handler: function (newFilters) {
        this.debounceFunction(this.getStandardsAndSetData(newFilters));
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getStandardsAndSetData(this.importedStandardsFilters);
      this.getAllNonImportedStandardsAndSetData(this.nonImportedFilters);
    },
    handleMyStandardClick(id) {
      this.$router.push(`/standards/${id}/details`);
    },
    getStandardsAndSetData(params) {
      this.getStandards(params)
        .then((res) => {
          this.items = res.data.data.data;
          this.importedStandardsFilters.page = res.data.data.current_page;
          this.importedStandardsPagination.perPage = res.data.data.per_page;
          this.importedStandardsPagination.total = res.data.data.total;
          this.importedStandardsPagination.lastPage = res.data.data.last_page;
        })
        .catch((err) => {
          handleError(err);
        });
    },

    handleImportStandardClick(id) {
      this.showOverlay();
      this.importedStandard(id)
        .then((res) => {
          this.load();
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.hideOverlay();
        });
    },

    getAllNonImportedStandardsAndSetData(filters) {
      this.overlay = true;
      this.getNonImportedStandards(filters)
        .then((res) => {
          console.log("Non Imported Standards", res);
          this.nonImportedStandards = res.data.data.data;
          this.nonImportedFilters.page = res.data.data.current_page;
          this.nonImportedPagination.perPage = res.data.data.per_page;
          this.nonImportedPagination.total = res.data.data.total;
          this.nonImportedPagination.lastPage = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    getImportedStandard(standard) {
      this.items.push(standard);
    },

    showOverlay() {
      this.overlay = true;
    },
    hideOverlay() {
      this.overlay = false;
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>
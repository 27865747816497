<template>
  <div>
    <div
      class="
        d-flex
        justify-content-between
        align-items-center
        content-sidebar-header
        px-2
        py-1
      "
    >
      <div class="d-flex flex-row justify-content-start align-items-center">
        <feather-icon size="20" icon="EditIcon" class="mr-1" />
        <h2 style="text-align: center; margin-bottom: 0">
          Non-Imported Standards
        </h2>
      </div>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <div class="todo-application">
      <div class="content-area-wrapper">
        <div class="content-right bg-white" style="width: 100%">
          <div class="content-body">
            <div class="todo-app-list">
              <div
                class="
                  app-fixed-search
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <div
                  class="
                    d-flex
                    align-content-center
                    justify-content-between
                    w-75
                  "
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="filters.search"
                      placeholder="Search Standards..."
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>

              <vue-perfect-scrollbar
                v-if="standards !== []"
                :settings="{ maxScrollbarLength: 120 }"
                class="todo-task-list-wrapper list-group scroll-area"
              >
                <draggable
                  v-model="standards"
                  handle="draggable-task-handle"
                  tag="ul"
                  class="todo-task-list media-list"
                  id="todo-task-list"
                >
                  <li
                    v-for="standard in standards"
                    :key="standard._id"
                    class="todo-item"
                  >
                    <div class="todo-title-wrapper">
                      <div class="todo-title-area">
                        <div class="title-wrapper">
                          <span v-if="standard" class="todo-title text-primary"><strong>{{
                            standard.name
                          }}</strong></span>
                        </div>
                      </div>

                      <div class="todo-item-action">
                        <div class="text-nowrap mr-1">
                          <b-button
                            @click="handleImportStandardClick(standard._id)"
                            v-b-tooltip.hover
                            title="Import Standard"
                            size="sm"
                            class="btn-icon rounded-circle"
                            variant="success"
                            ><feather-icon icon="PlusIcon"
                          /></b-button>
                        </div>
                      </div>
                    </div>
                  </li>
                </draggable>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import StandardMixins from "../../../mixins/StandardMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";

import {
  BButton,
  VBTooltip,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  name: "NonImportedStandardsView",
  mixins: [StandardMixins, ResponseMixins, UtilsMixins],
  props: {
    openSidebar: {
      type: Function,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    showOverlay: {
      type: Function,
      required: true,
    },
    hideOverlay: {
      type: Function,
      required: true,
    },
    reloadParent:{
      type: Function,
      required: true,
    }
  },
  directives: { "b-tooltip": VBTooltip },
  components: {
    VuePerfectScrollbar,
    draggable,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
  },
  data() {
    return {
      standards: [],
      filters:{
        search:null,
      },
      
    };
  },
  watch:{
    filters: {
      handler: function () {
        this.debounceFunction(this.getAllNonImportedStandardsAndSetData);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    
    load() {
      this.getAllNonImportedStandardsAndSetData();
    },
    getAllNonImportedStandardsAndSetData() {
      this.getNonImportedStandards(this.filters)
        .then((res) => {
          this.standards = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    handleImportStandardClick(id) {
      this.showOverlay();
      this.importedStandard(id)
        .then((res) => {
          this.removeStandardFromData(id);
          this.reloadParent();
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.hideOverlay();
        });
    },

    removeStandardFromData(id) {
      let newStandards = this.standards.filter(
        (standard) => standard._id !== id
      );
      this.standards = newStandards;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>